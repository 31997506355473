import { Outlet } from "@solidjs/router";
import { AuthViewProps } from "../models";
import { Show, splitProps } from "solid-js";
import { Cmp, Ctrl, log } from ":mods";
export function Auth(props: AuthViewProps) {
  // const [local] = splitProps(props, ["guard"]);
  const { id } = Ctrl.Context.hooks.useAppFlavor();
  const is_mobile = window.matchMedia("only screen and (max-width: 760px)").matches;
  return (
    <Show when={is_mobile} fallback={<Outlet />}>
      <div>
        <h1 class="text-1.2rem capitalize">{id}</h1>
        <h3 class="text-0.5rem">support for mobile browsers is in action, we are very sorry for this inconvenience!</h3>
      </div>
    </Show>
  );
}
